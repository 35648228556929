import React from 'react';
import './Projects.css';
import nt_1 from '../media/projects/NutriTiger_hp.png'
import nt_2 from '../media/projects/NutriTiger_hp2.png'
import pa5_1 from '../media/projects/PA5_chart.png'
import pa5_2 from '../media/projects/PA5_chart2.png'
import s_1 from '../media/projects/Seam_1.png'
import s_2 from '../media/projects/Seam_2.png'

function Project() {
    return(
        <div>
            <h1 className='p-hi'>Projects</h1>
        <div className='container'>
        <div className='p-container'>

            <div className='project'>
                    <h3 className='p-subtitle'>NutriTiger - 2024</h3>

                    <strong className='p-strong'>A full-stack web app. Python, HTML/CSS, JavaScript.</strong> <br/>
                    <img src={nt_1} className='p-image2' alt=''/>
                    <img src={nt_2} className='p-image2' alt=''/>

                    <p className='p-description'>
                        NutriTiger is a web app for Princeton Students that aims
                        to conveniently provide nutritional data for the Princeton dining hall foods.
                        It also provides meal logging and macronutrient tracking over time. NutriTiger was
                        created in a team of 5 for COS 333.
                    </p>

                    <strong className='p-strong'>Features:</strong>
                    <ul className='p-description'>
                        <li>User authentication to only recognize Princeton email accounts.</li>
                        <li>Usage of cookies to identify first-time or returning users.</li>
                        <li>An XML web scraper to get the nutritional information for the foods.</li>
                        <li>Data stored in MongoDB database, optimized for storing and retrieving data.</li>
                    </ul>
                </div>

                <div className='project'>
                    <h3 className='p-subtitle'>Multiclass Image Classifier - 2024</h3>
                    <strong className='p-strong'>Machine Learning. Python, using the PyTorch package.</strong> <br/>
                    
                    <div className='p-img-container'>
                    <div className='p-imgbox'>
                    <img src={pa5_1} className='p-image' alt=''/>
                    </div>
                    <img src={pa5_2} className='p-image2' alt=''/>
                    </div>
                    <p className='p-description'> Designed and trained a classifier based on convolutional neural networks
                        (CNNs) to classify images of handwritten numerical digits. Obtained a prediction accuracy of 99.02% on test digit sets. 
                    </p>
                </div>

                <div className='project'>
                    <h3 className='p-subtitle'>Seam Carving - 2023</h3>
                    <strong className='p-strong'>Data Structures & Algorithms. Java.</strong><br/>

                    <img src={s_1} className='p-image2' alt=''/>
                    <img src={s_2} className='p-image2' alt=''/>

                    <p className='p-description'> A content-aware image resizing algorithm by identifying the 
                    least significant pathways in the image pixels, utilizing Dijkstra’s shortest path algorithm
                    and dynamic programming.
                    </p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Project;