import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import './About.css';

function About() {
    const [selected, setSelected] = useState('bio');

    const renderContent = () => {
        switch (selected) {
            case 'bio':
                return (
                    <div className='a-container'>
                        <h4 className='bigtext subtitle' style={{ display: "inline-block" }}>I am a junior at Princeton 
                            University majoring in <strong>Computer Science</strong> with a minor in 
                             Technology & Society.</h4>
                        <br/>
                        
                        <p className='p-big'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <br/>
                        <br/>
                        <strong >more about me</strong><br/><br/>
                            I am a Korean American from Greensboro, North Carolina.<br/>
                            I have a strong interest in <strong>software engineering + frontend development</strong>.<br/>
                            Outside of CS, I am also interested in <strong>sociology + social impact work</strong>.<br/>
                            I spend all of my time creating.
                        <br/>
                        <br/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <br/>
                        <br/>
                        <strong >extracurriculars</strong><br/>
                        <br/>
                         BodyHype Dance Co. <br/>
                         Koko Pops Dance Co. <br/>
                         Triple 8 Dance Co. <br/>
                         Manna Christian Fellowship <br/>
                         E-Club Design Team <br/>
                         Campus Rec Marketing Team <br/>
                            <br/>                        
                        </p>
                    </div>
                );
            case 'skills':
                return (
                    <div className='a-container'>
                        <p className='arrow-subtitle'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> programming languages </p>
                        <p className='p-big'>Java, Python, C, Go <br/>
                        HTML/CSS, JavaScript, React</p>

                        <p className='arrow-subtitle'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> design </p>
                        <p className='p-big'>Adobe Photoshop, Adobe Illustrator<br/>
                        Figma, Wireframing + Prototyping, Canva</p>

                        <p className='arrow-subtitle'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> languages </p>
                        <p className='p-big'>English (fluent), Korean (heritage speaker)</p>
                    </div>
                );
            case 'hobbies':
                return (
                    <div className='a-container'>
                        <h4 className='bigtext subtitle'>I'm always creating! Here are some of my endeavors:</h4>
                        <br />
                        <p className='p-big'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <strong> studio art:</strong> drawing, painting, etc... See my <Link to="/sketchbook">sketchbook gallery</Link> for a sneak peek. </p>
                        
                        <p className='p-big'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <strong> crochet:</strong> this is my classic winter break activity. I make plushies, clothes, bags, and keychains.
                        This is also my go-to birthday present for my friends. </p>
                        
                        <p className='p-big'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <strong> dance:</strong> although I only started dancing in college, It has been one
                        of my favorite outlets. I spend a lot of time rehearsing and learning new choreo with my friends. </p>
                        
                        <p className='p-big'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="navy" class="bi bi-asterisk" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1"/>
                        </svg> <strong> baking:</strong> At home, I bake a lot of cakes with my mom. My specialty is french macarons and cake decorating. </p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='btn-container'>
            <h1 className='hi'>About</h1>

            {/* Radio buttons for Bio/Skills/Hobbies */}
            <div className='btngrp-pos'>
                <ButtonGroup aria-label="outlined button group">
                    <Button 
                        className={`btn-outline ${selected === "bio" ? "active" : ""}`} 
                        onClick={() => setSelected('bio')}
                    >
                        Bio
                    </Button>
                    <Button 
                        className={`btn-outline ${selected === "skills" ? "active" : ""}`} 
                        onClick={() => setSelected('skills')}
                    >
                        Skills
                    </Button>
                    <Button 
                        className={`btn-outline ${selected === "hobbies" ? "active" : ""}`} 
                        onClick={() => setSelected('hobbies')}
                    >
                        Hobbies
                    </Button>
                </ButtonGroup>
            </div>

            <div className="content mt-3">
                {renderContent()}
            </div>
        </div>
    );
}

export default About;
